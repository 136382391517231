import SEO from '@connected/SEO';
import NewsAndEventsList, { ArticleModel, thumbnail } from '@presentation/NewsAndEventsList';
import SimpleBanner from '@presentation/SimpleBanner';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';
import { Gatsby } from 'schema/Gatsby';

interface EventListProps {
  data: {
    articleList: ContentModels.ContentfulArticleList;
    eventList: ContentModels.ContentfulEventList;
    events: Gatsby.Connection<ContentModels.ContentfulEvent>;
  };
}

const EventList: React.FC<EventListProps> = ({ data }) => {
  return (
    <>
      <SEO data={data.eventList.metadata} url={data.eventList.url} />
      <SimpleBanner title={data.eventList.title} subtitle={data.eventList.subtitle} />
      <NewsAndEventsList
        currentPage={data.events.pageInfo?.currentPage ?? 1}
        eventsUrl={data.eventList.url ?? '/'}
        isEvents
        isNews={false}
        items={createItemArray(data.events.edges?.map((e) => e.node))}
        newsUrl={data.articleList.url ?? '/'}
        noResultsText={data.eventList.noResultsText?.noResultsText ?? ''}
        pageCount={data.events.pageInfo?.pageCount ?? 1}
        hrefBuilder={(pageIndex) => {
          return getPagedUrl(data.eventList.url ?? '/', pageIndex);
        }}
        onPageChange={({ selected: pageIndex }) => {
          const to = getPagedUrl(data.eventList.url ?? '/', pageIndex + 1);
          navigate(to);
        }}
      />
    </>
  );
};

export default EventList;

function getPagedUrl(baseUrl: string, pageIndex: number): string {
  if (pageIndex === 1) {
    return baseUrl ?? '/';
  }

  return `${baseUrl}/${pageIndex}`;
}

function createItemArray(entries?: ContentModels.ContentfulEvent[]): ArticleModel[] {
  return entries?.map(createItemModel) ?? [];
}

function createItemModel(entry: ContentModels.ContentfulEvent): ArticleModel {
  return {
    id: entry.id ?? '',
    date: new Date(entry.date ?? ''),
    thumbnail: thumbnail(entry.thumbnail?.[0]?.public_id),
    title: entry.title ?? '',
    url: entry.url ?? '',
  };
}

export const query = graphql`
  query EventListQuery($limit: Int!, $now: Date = "", $skip: Int!) {
    articleList: contentfulArticleList {
      url
    }
    eventList: contentfulEventList {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      noResultsText {
        noResultsText
      }
      subtitle
      title
      url
    }
    events: allContentfulEvent(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: ASC }
      filter: { date: { gte: $now } }
    ) {
      edges {
        node {
          date
          thumbnail
          title
          url
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
